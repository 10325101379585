.customDatePicker input {
  padding: 8.5px;
}
.overlay {
  display: none;
}
.dataGrid *:focus-within {
  outline: none !important;
}

.Hello:first-child div {
  background-color: #0f0108;
}

.evenRow {
  background-color: white;
}

.oddRow {
  background-color: #f4f3f8;
}

@media screen and (max-width: 576px) {
  .boxOpen header {
    margin-left: 0;
    width: 100%;
  }
  .boxOpen .mainDrawer {
    position: fixed;
    z-index: 555555;
  }
  .boxOpen .mainContent {
    margin-left: -240px;
  }
  body:has(.boxOpen) {
    overflow: hidden;
  }
  .boxOpen .overlay {
    position: absolute;
    inset: 0;
    display: block;
    z-index: 55555;
    background-color: #000000;
    opacity: 0.7;
  }
  .customDatePicker {
    width: 55%;
  }
  .customDatePicker div {
    width: 100%;
  }
}
